<template>
	<div>
		<div class="basicdata flex align_items_c direction_column">
			<div class="basicdata_title padding_bot_0 flex align_items_c">
				<div class="basicdata_title_img">
					<img
						src="https://zy.metaera.media/assets/images/my/basicdata_mine_edit.png"
						alt=""
					/>
				</div>
				<div class="basicdata_title_text">Edit personal information</div>
			</div>
			<div
				class="basicdata_2_item padding_bot_0 flex align_items_c"
				style="height: 100px"
			>
				<div class="my_zl_left">
					<span>头像</span>
				</div>
				<div class="my_zl_right basicdata_1">
					<img :src="userInfo.avatar_img" alt="" v-if="userInfo.avatar_img" />
					<div v-else>
						<img src="https://zy.metaera.media/user_header.png" alt="" />
					</div>
					<!-- 剪裁组件弹窗 -->
					<el-dialog
						title="裁切图片"
						:visible.sync="cropperModel"
						width="1300px"
						center
						:append-to-body="true"
					>
						<template v-if="cropperModel">
							<cropper-image-new
								:Name="cropperName"
								:type="scale"
								:showAvator="true"
								@uploadImgSuccess="handleUploadSuccess"
								ref="child"
							>
							</cropper-image-new
						></template>
					</el-dialog>
					<div
						class="changeHead flex align_items_c justify_content_c"
						@click="uploadPicture()"
					>
						上传头像
					</div>
					<div class="changeHead_tip flex align_items_c justify_content_c">
						圖片格式支持 GIF、PNG、JPEG，尺寸不小於 200 PX，小於 4 MB
					</div>
				</div>
			</div>
			<div class="basicdata_2 accountInfo">
				<div class="basicdata_2_item padding_bot_0 flex align_items_c">
					<div class="my_zl_left">
						<span>昵称</span>
					</div>
					<div class="my_zl_right">
						<input
							type="text"
							class="modify-nickname-input"
							v-model="userInfo.nickname"
						/>
					</div>
				</div>
				<div class="basicdata_2_item flex align_items_c">
					<div class="my_zl_left">
						<span>UID</span>
					</div>
					<div class="my_zl_right"
						><span>{{ userInfo.id }}</span></div
					>
				</div>
				<div class="basicdata_2_item flex align_items_c">
					<div class="my_zl_left">
						<span>性别</span>
					</div>
					<div class="my_zl_right"
						><span>
							<el-radio v-model="userInfo.sex" label="1">男</el-radio>
							<el-radio v-model="userInfo.sex" label="2">女</el-radio>
						</span></div
					>
				</div>
				<div
					class="basicdata_2_item margin_top_0 padding_bot_0 flex align_items_c"
				>
					<div class="my_zl_left">
						<span>介绍</span>
					</div>
					<div class="my_zl_right">
						<input
							type="text"
							class="modify-nickname-input"
							v-model="userInfo.desc"
							placeholder="请介绍一下你自己"
						/>
					</div>
				</div>
				<div
					class="basicdata_2_item margin_top_0 padding_bot_0 flex align_items_c"
				>
					<div class="my_zl_left">
						<span>邮箱</span>
					</div>
					<div class="my_zl_right">
						<input
							type="text"
							class="modify-nickname-input"
							v-model="userInfo.email"
							placeholder="请填写您的邮箱"
					/></div>
				</div>
				<div
					class="subBtn flex align_items_c justify_content_c"
					@click="submit"
				>
					保存
				</div>
			</div>
		</div>
		<!-- 账户安全 -->
		<div class="ztbox">
			<div class="basicdata_title padding_bot_0 flex align_items_c">
				<div class="basicdata_title_img">
					<img
						src="https://zy.metaera.media/assets/images/my/basicdata_mine_phone.png"
						alt=""
					/>
				</div>
				<div class="basicdata_title_text">Account security</div>
			</div>

			<div class="ztbox_2">
				<div class="ztbox_2_item flex">
					<div class="flex align_items_c ztbox_2_item_left">
						<span class="ztbox_2_item_title">手机号</span>
						<span style="margin-left: 30px">{{ this.mobile }}</span>
					</div>
					<div
						class="ztbox_2_item_cz"
						@click="
							editPhoneDis = true;
							showYz = '1';
						"
						>修改手機號</div
					>
				</div>
				<div class="ztbox_2_item flex ztbox_2_item_left">
					<div class="flex align_items_c">
						<span class="ztbox_2_item_title">密码</span>
					</div>
					<div class="ztbox_2_item_cz" @click="editPassDis = true"
						>修改密碼</div
					>
				</div>
			</div>
		</div>
		<!-- 修改手机号码 -->
		<el-dialog :visible.sync="editPhoneDis">
			<div>
				<section
					class="bomb modify-pwd phone-edit-first-dialog"
					style="display: block"
				>
					<h3>Modify</h3>
					<div v-if="showYz == '1'">
						<i class="cross"></i>
						<section
							class="modify-pwd-information"
							v-if="this.localLanguage != 2"
						>
							<p>为确保账号安全，需要验证当前手机有效性</p>
							<p>当前绑定手机号：{{ userInfo.mobile }}</p>
						</section>
						<section class="modify-pwd-information" v-else>
							<p
								>To ensure account security, verify the validity of the current
								mobile phone</p
							>
							<p>Current binding phone number:{{ userInfo.mobile }}</p>
						</section>
						<section class="bomb-row-vcode clearfix">
							<select
								name="country_code"
								class="ddl-select phone-code-input-edit-second"
								style="display: none"
							>
								<option value="86">
									中国
									<span style="display: none"> (86) </span>
								</option>
							</select>
							<p class="fifty-height">
								<input
									type="text"
									name="captcha"
									placeholder="请输入验证码"
									title="请输入验证码"
									v-model="code"
									class="fl msgcode bindmsg"
								/>
								<input
									id="vercode1"
									value="获取验证码"
									type="button"
									title="获取验证码"
									class="fl msgcode-btn captcha-validate-bt vercode2 send-authcode"
									@click="tosendSms"
									v-show="show"
								/>
							</p>

							<div
								class="fl msgcode-btn captcha-validate-bt vercode2 send-authcode djs"
								v-show="!show"
							>
								{{ count }} s</div
							>
						</section>
					</div>
					<div v-if="showYz == '2'" class="flex justify_content_c">
						<input
							type="text"
							placeholder="请输入手机号码"
							title="请输入验证码"
							v-model="loginForm.mobile"
							class="fl msgcode bindmsg nofloat"
						/>
					</div>
					<div class="confirm-cancel clearfix" style="margin-top: 20px">
						<a
							href="javascript:;void(0)"
							v-if="showYz == '1'"
							class="unbundled-click2 fl unbundled-password-left4 phone-edit-second"
							@click="nextYz"
							>下一步</a
						>
						<a
							href="javascript:;void(0)"
							v-if="showYz == '2'"
							class="unbundled-click2 fl unbundled-password-left4 phone-edit-second"
							@click="confirm"
							>确定</a
						>
						<a
							@click="editPhoneDis = false"
							href="javascript:;void(0)"
							class="unbundled-click2 fl cancel"
							>取消</a
						>
					</div>
					<div class="vercode-error1s vercode-error" style="display: none">
						<p></p>
					</div>
				</section>
			</div>
		</el-dialog>
		<!-- 修改密码 -->
		<el-dialog :visible.sync="editPassDis">
			<div>
				<section
					class="bomb modify-pwd phone-edit-first-dialog"
					style="display: block"
				>
					<h3 v-if="this.localLanguage != 2">修改</h3>
					<h3 v-else>Modify</h3>
					<div v-if="showYz == '1'">
						<i class="cross"></i>
						<section
							class="modify-pwd-information"
							v-if="this.localLanguage != 2"
						>
							<p>为确保账号安全，需要验证当前手机有效性</p>
							<p>当前绑定手机号：{{ userInfo.mobile }}</p>
						</section>
						<section class="modify-pwd-information" v-else>
							<p
								>To ensure account security, verify the validity of the current
								mobile phone</p
							>
							<p>Current binding phone number:{{ userInfo.mobile }}</p>
						</section>
						<section class="bomb-row-vcode clearfix">
							<select
								name="country_code"
								class="ddl-select phone-code-input-edit-second"
								style="display: none"
							>
								<option value="86">
									中国
									<span style="display: none"> (86) </span>
								</option>
							</select>
							<p class="fifty-height">
								<input
									type="text"
									name="captcha"
									placeholder="请输入验证码"
									title="请输入验证码"
									v-model="code"
									class="fl msgcode bindmsg"
								/>
								<input
									id="vercode1"
									value="获取验证码"
									type="button"
									title="获取验证码"
									class="fl msgcode-btn captcha-validate-bt vercode2 send-authcode"
									@click="tosendSms"
									v-show="show"
								/>
							</p>

							<div
								class="fl msgcode-btn captcha-validate-bt vercode2 send-authcode djs"
								v-show="!show"
							>
								{{ count }} s</div
							>
						</section>
						<input
							type="password"
							name="password"
							placeholder="登录密码"
							title="登录密码"
							v-model="new_password1"
							class="bomb-row40 unbundled-input1 bomb-row-border unbundled-input2 unbundled-input-password1"
						/>
						<input
							type="password"
							name="password_confirmation"
							placeholder="请再次输入新密码"
							title="请再次输入新密码"
							v-model="new_password2"
							class="bomb-row40 unbundled-input2 bomb-row-border unbundled-input-password2"
						/>
					</div>

					<div class="confirm-cancel clearfix" style="margin-top: 20px">
						<a
							href="javascript:;void(0)"
							class="unbundled-click2 fl unbundled-password-left4 phone-edit-second"
							@click="editPass"
							disabled
							>确定</a
						>

						<a
							@click="editPassDis = false"
							href="javascript:;void(0)"
							class="unbundled-click2 fl cancel"
							>取消</a
						>
					</div>
					<div class="vercode-error1s vercode-error" v-if="errorShow">
						<p>{{ errorVal }}</p>
					</div>
				</section>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	getInfo,
	uploadImage,
	editUserInfo,
	verifySms,
	setPassword,
} from "@/api/user";
import { sendSms } from "@/api/home";
import { getToken, getUserMobile } from "@/utils/auth";
import login__areaCode from "@/components/login/login__areaCode.vue";

import { eventBus } from "../../../components/pc/leftTab";
export default {
	name: "",
	components: { login__areaCode },
	data() {
		return {
			//裁切图片参数
			cropperModel: false,
			cropperName: "",
			errorShow: false,
			errorVal: "",
			active: 0,
			userInfo: {},
			editPhoneDis: false,
			editPassDis: false,
			// 验证码
			show: true,
			timer: null,
			count: "",
			code: "",
			new_password1: "",
			new_password2: "",
			showYz: "1",
			showtimezone: false,
			mobile: "",
			loginForm: {
				mobile: "",
				code: "",
				phone_code: "86",
			},
		};
	},
	created() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
		this.mobile = getUserMobile();
		this.getInfo();
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		//封面设置
		uploadPicture(name) {
			this.cropperName = name;
			this.type = "1";
			this.cropperModel = true;
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			this.userInfo.avatar_img = data.url;
		},

		getInfo() {
			getInfo({})
				.then((res) => {
					if (res.code == 200) {
						this.userInfo = res.data;
						this.userInfo.sex = this.userInfo.sex.toString();
					}
				})
				.catch(() => {});
		},
		submit() {
			editUserInfo({
				nickname: this.userInfo.nickname,
				avatar_img: this.userInfo.avatar_img,
				sex: this.userInfo.sex,
				desc: this.userInfo.desc,
				official_account: this.userInfo.official_account,
			})
				.then((res) => {
					if (res.code == 200) {
						this.$message({
							message: "修改成功",
							type: "success",
							duration: 2 * 1000,
						});
						this.getInfo();
						location.reload();
					}
				})
				.catch(() => {});
		},
		tosendSms2() {
			sendSms({
				phone: this.loginForm.mobile,
				phone_code: this.loginForm.phone_code,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		tosendSms() {
			sendSms({
				phone: this.userInfo.mobile,
				phone_code: this.userInfo.phone_code ? this.userInfo.phone_code : "86",
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		nextYz() {
			if (this.code == "") {
				alert("请输入验证码！");
			} else {
				verifySms({
					mobile: this.userInfo.mobile,
					code: this.code,
					phone_code: this.userInfo.phone_code
						? this.userInfo.phone_code
						: "86",
				})
					.then((res) => {
						if (res.code == 200) {
							this.showYz = "2";
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			}
		},
		getphone_code(val) {
			this.loginForm.phone_code = val;
		},
		confirm() {
			if (this.loginForm.mobile == "") {
				alert("请输入手机号！");
			} else {
				editUserInfo({
					phone: this.loginForm.mobile,
				})
					.then((res) => {
						if (res.code == 200) {
							this.$message({
								message: "修改成功",
								type: "success",
								duration: 2 * 1000,
							});
							this.editPhoneDis = false;
							this.getInfo();
						}
					})
					.catch(() => {});
			}
		},
		editPass() {
			let that = this;
			if (this.code == "") {
				this.errorVal = "验证码输入错误，请重新输入！";
				this.errorShow = true;
				var timer = setTimeout(function () {
					that.errorShow = false;
				}, 2000);
			} else if (this.new_password1 == "" || this.new_password2 == "") {
				this.errorVal = "请输入密码！";
				this.errorShow = true;
				var timer = setTimeout(function () {
					that.errorShow = false;
				}, 2000);
			} else if (this.new_password1 != this.new_password2) {
				this.errorVal = "两次密码输入不一致！";
				this.errorShow = true;
				var timer = setTimeout(function () {
					that.errorShow = false;
				}, 2000);
			} else {
				setPassword({
					mobile: this.userInfo.mobile,
					phone_code: this.userInfo.phone_code
						? this.userInfo.phone_code
						: "86",
					code: this.code,
					new_password1: this.new_password1,
					new_password2: this.new_password2,
				})
					.then((res) => {
						if (res.code == 200) {
							this.$message({
								message: "修改成功",
								type: "success",
								duration: 2 * 1000,
							});
							this.editPassDis = false;
							this.getInfo();
						}
					})
					.catch(() => {});
			}
		},
	},
};
</script>

<style scoped>
.indivdualtab div {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #888888;
	margin-top: 30px;
}

.active {
	color: #272525;
}

.indivdualtab div:nth-child(2) {
	margin-left: 36px;
}

.indivdualtab_line {
	width: 100%;
	height: 1px;
	background: #e1e1e1;
	margin-top: 9px;
}
.ztbox {
	background: #ffffff;
	padding-left: 25px;
	padding-right: 25px;
	margin-top: 20px;
	padding-top: 5px;
	border-radius: 20px;
	opacity: 1;

	background: #ffffff;

	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

.ztbox_1 {
	width: 100%;
	height: 40px;
	background: #ebf4fe;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
	font-size: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.ztbox_2 {
	margin-top: 57px;
	border-radius: 20px;
	opacity: 1;
}

.ztbox_2_item {
	padding-bottom: 25px;
	margin-top: 27px;
}

.ztbox_2_item img {
	width: 20px;
	margin-right: 6px;
}

.ztbox_2_item span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.ztbox_2_item_cz {
	width: 118px;
	height: 40px;
	border-radius: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	background: #eff5fe;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	align-items: center;
	justify-content: center;
	text-align: center;
	display: flex;
	margin-left: 20px;
	/* 黑色主色（字段一级） */
	color: #333333;
}

/* 基本资料 */
.basicdata {
	background: #ffffff;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 20px;
	opacity: 1;

	background: #ffffff;

	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

.basicdata_1 {
	width: 715px;
	height: 119px;
	opacity: 1;
	margin-top: 59px;
	position: relative;
}

.basicdata_1 img {
	width: 80px;
	height: 80px;
	border: 1px solid #f4f4f5;
	border-radius: 20px;
	border-radius: 100px;
}

.changeHead {
	position: absolute;
	top: 4px;
	left: 160px;
	transform: translateX(-50%);
	width: 100px;
	height: 40px;
	background: #eff5fe;
	border-radius: 10px;
	opacity: 1;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.basicdata_2 {
	margin-top: 26px;
	width: 100%;
}

.basicdata_2_item {
	width: 100%;
	height: 70px;
}
.my_zl_left {
	display: inline-block;
	width: 150px;
}
.my_zl_left span {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}
.my_zl_right span {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #272525;
}
.my_zl_right input {
	background: #eff5fe;
	border-radius: 4px;
	padding-left: 10px;
	width: 715px;
	height: 40px;
	opacity: 1;
	border: 1px solid #fff;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}
.nicknameedit div:nth-child(1) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #767680;
}

.nicknameedit div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
	margin-left: 102px;
}

.basicdata_2_item_gzh {
	height: 280px;
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #0056ff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}

.avatar {
	width: 181px;
	height: 181px;
	display: block;
}

.avatar-uploader {
	width: 181px;
	height: 182px;
	background: #fafafa;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
	border: 1px solid #d1d1d1;
}

.ewmBox span {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #272525;
	margin-top: 14px;
}

.ewmBox {
	margin-left: 40%;
	transform: translateX(-50%);
}

.subBtn {
	width: 100%;
	height: 40px;
	background: #0056ff;
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #0056ff;
	/* margin: 86px auto; */
	margin-top: 80px;
	margin-bottom: 20px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

@import url("../../../assets/person.css");

.timezone-list {
	top: 60px;
}

.nofloat {
	float: none;
}
</style>
<style>
input::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #888888;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #888888;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #888888;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #888888;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

	font-size: 14px;
}

.basicdata_title {
	width: 100%;
	margin-top: 25px;
	display: flex;
}

.basicdata_title_img img {
	width: 16px;
	height: 16px;
	margin-right: 10px;
}
.basicdata_title_text {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.changeHead_tip {
	position: absolute;
	top: 50px;
	left: 115px;
	width: 600px;
	height: 40px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	opacity: 1;
	/* 字段三级 */
	color: #b2b2b2;
	justify-content: flex-start;
}
.el-radio__inner {
	border-radius: 4px;
}
.el-radio__label {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	text-align: justify; /* 浏览器可能不支持 */
	letter-spacing: 0px;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ztbox_2_item_title {
	width: 110px;
}
.ztbox_2_item_left {
	margin-right: 20px;
}
</style>
